/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.container-x-padding:not(.container-x-padding .container-x-padding) {
  @apply px-4 sm:px-6 lg:px-8;
}

.caption {
  @apply mt-2 text-base text-gray-700;
}

.text-link {
  @apply text-pif-rust hover:underline hover:text-pif-rust-dark decoration-1 underline-offset-2 decoration-pif-rust hover:decoration-pif-rust-dark;
  @apply group-hover:underline group-hover:text-pif-rust-dark group-hover:decoration-pif-rust-dark;
}

.prose {
  @apply text-pif-brown;
  @apply prose-a:font-normal prose-a:text-pif-rust prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-pif-rust;
  @apply hover:prose-a:text-pif-rust-dark hover:prose-a:decoration-pif-rust-dark;
  @apply prose-headings:font-normal prose-headings:mb-4 prose-headings:pt-2 prose-headings:text-pif-bronze;
  @apply prose-strong:text-pif-bronze;
  @apply prose-h2:font-sans prose-h2:uppercase prose-h2:tracking-wider prose-h2:text-2xl;
  @apply prose-h3:text-xl;
  @apply prose-ol:ml-7;
}

.prose {
  @apply prose-blockquote:text-current
}

.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.prose a[href^="#fn-"] {
  @apply p-1 text-base leading-none no-underline text-pif-rust hover:underline hover:text-white size-5 align-super hover:bg-pif-rust;
}


.btn {
  @apply inline-flex items-center justify-center border border-transparent rounded-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply font-sans tracking-wider no-underline uppercase;
}

.btn-primary {
  @apply text-white hover:text-white hover:bg-pif-brown group-hover:bg-pif-brown bg-pif-bronze;
}

.btn-secondary {
  @apply text-pif-brown bg-pif-silver hover:bg-pif-brown group-hover:bg-pif-brown focus:ring-pif-brown;
}

.btn-link {
  @apply text-blue-700 underline hover:text-blue-900 focus:ring-blue-500;
}

.btn-outline-primary {
  @apply border text-pif-bronze border-pif-bronze hover:border-pif-brown;
}

.btn-outline-secondary {
  @apply border text-pif-silver border-pif-silver hover:text-pif-brown hover:border-pif-brown focus:ring-pif-brown;
}

.btn-lg {
  @apply px-12 py-2 text-lg tracking-wider;
}

.btn-md {
  @apply px-6 py-2 text-base tracking-wider;
}

.btn-sm {
  @apply px-4 py-2.5 text-sm tracking-wider;
}

.form-label,
.form-legend {
  @apply inline-block font-medium;
}

.form-label {
  @apply mb-1 text-pif-bronze;
}

.form-legend {
  @apply mb-2.5 text-base;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.form-checkbox,
.form-radio {
  @apply font-sans border-pif-bronze focus:ring-pif-bronze;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  @apply block w-full border shadow-sm focus:border-pif-bronze;
}

.form-checkbox,
.form-radio {
  @apply w-4 h-4 mt-0 mr-1 text-pif-bronze;
}

.form-checkbox {
  @apply mt-0 mr-1;
}

.form-checkbox:disabled {
  @apply opacity-50;
}

.quotation-marks::before {
  content: url('/images/quotation-mark-left.svg');
  @apply absolute w-16 h-16 -ml-3 sm:mt-2 lg:-ml-8;
}

.quotation-marks p:last-child span:last-child::after {
  content: url('/images/quotation-mark-right.svg');
  @apply absolute w-16 h-16 sm:mt-2.5 -ml-5;
}
